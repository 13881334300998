import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`Today is our free CrossFit class at 9:00 & 10:00am so bring a
friend for a partner ****`}</strong>{`wod.  Or come alone and we’ll pair you up
with someone.***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit the Ville day at Climb Nulu is also today at 11:30. All
members and their families are invited.  Let us know if you can make
it! `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Next Pop Up Mobility classes with Glenn be this Sunday, March
10th, at 12:00am. This is free for all members, \\$10 for anyone else, so
pop in if you can make it!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      